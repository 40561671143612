import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Grid, withStyles, CircularProgress, Typography } from '@material-ui/core'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
// import treeChanges from 'tree-changes'

import { STATUS } from 'redux/constants/index'

import style from './style'
import { getShopSettings, getShopGroups } from 'redux/actions'
import { Payment, ReserveSettings, OrderSettings, OtherSettings } from '..'

class Settings extends Component {
  componentWillMount = () => {
    const { dispatch, shopId } = this.props
    dispatch(getShopSettings(shopId))
    dispatch(getShopGroups())
  }

  render = () => {
    const { classes, settings } = this.props
    if (settings.status === STATUS.RUNNING || settings.status === STATUS.IDLE) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      )
    }

    if (settings.status === STATUS.ERROR) {
      return (
        <Typography variant='h6'>There are no shops available for this details</Typography>
      )
    }
    return (
      <React.Fragment>
        <Grid
          item
          lg={8}
          md={6}
          xl={8}
          xs={12}
        >
          <Grid xs={12}>
            <Payment shopId={this.props.shopId} />
          </Grid>
          <Grid xs={12} container spacing={1} className={classes.orderSettings}>
            <Grid xs={6} item>
              <OrderSettings shopId={this.props.shopId} />
            </Grid>
            <Grid xs={6} item>
              <OtherSettings shopId={this.props.shopId} />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          lg={4}
          md={6}
          xl={4}
          xs={12}
        >
          <ReserveSettings shopId={this.props.shopId} />
        </Grid>
      </React.Fragment>
    )
  }
}

Settings.propTypes = {
  classes: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
    settings: state.shops.settings
  }
}

export default compose(
  withRouter,
  withStyles(style)
)(connect(mapStateToProps)(Settings))
