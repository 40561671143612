// @flow
/**
 * @module Actions/User
 * @desc User Actions
 */
import { createActions } from 'redux-actions'

import { ActionTypes } from 'redux/constants/index'

export const {
  getShops,
  getShopDetails,
  getShopProducts,
  getShopActiveOrders,
  getShopCompletedOrders,
  verifyShop,
  getShopLocation,
  deleteShop,
  getNewShops,
  exportProduct,
  getShopSettings,
  updateShopSettings,
  editShopLogo,
  getShopSlots,
  getShopSlotDetails,
  updateShopSlot,
  addShopSlot,
  deleteShopSlot,
  getShopAnalytics,
  getShopGroups,
} = createActions({
  [ActionTypes.GET_SHOPS]: (skip, limit, searchString) => ({ skip, limit, searchString }),
  [ActionTypes.GET_NEW_SHOPS]: (skip, limit, searchString) => ({ skip, limit, searchString }),
  [ActionTypes.GET_SHOP_DETAILS]: (id) => ({ id }),
  [ActionTypes.GET_SHOP_PRODUCTS]: (id, skip, limit) => ({ id, skip, limit }),
  [ActionTypes.GET_SHOP_ACTIVE_ORDERS]: (id, skip, limit) => ({ id, skip, limit }),
  [ActionTypes.GET_SHOP_COMPLETED_ORDERS]: (id, skip, limit) => ({ id, skip, limit }),
  [ActionTypes.VERIFY_SHOP]: (id, status) => ({ id, status }),
  [ActionTypes.GET_SHOP_LOCATION]: () => ({ }),
  [ActionTypes.DELETE_SHOP]: (id) => ({ id }),
  [ActionTypes.EXPORT_PRODUCT]: (shopId, id) => ({ shopId, id }),
  [ActionTypes.GET_SHOP_SETTINGS]: (id) => ({ id }),
  [ActionTypes.UPDATE_SHOP_SETTINGS]: (id, requestObj, type) => ({ id, requestObj }),
  [ActionTypes.EDIT_SHOP_LOGO]: (id, url) => ({ id, url }),
  [ActionTypes.GET_SHOP_SLOTS]: (id) => ({ id }),
  [ActionTypes.GET_SHOP_SLOT_DETAILS]: (shopId, id) => ({ shopId, id }),
  [ActionTypes.UPDATE_SHOP_SLOT]: (shopId, id, requestObj) => ({ shopId, id, requestObj }),
  [ActionTypes.ADD_SHOP_SLOT]: (shopId, requestObj) => ({ shopId, requestObj }),
  [ActionTypes.DELETE_SHOP_SLOT]: (shopId, id) => ({ shopId, id }),
  [ActionTypes.GET_SHOP_ANALYTICS]: (shopId) => ({ shopId }),
  [ActionTypes.GET_SHOP_GROUPS]: () => ({ })
})
