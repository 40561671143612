import { handleActions } from 'redux-actions'
import immutable from 'immutability-helper'
import { parseError } from 'services/client'
import _ from 'lodash'

import { ActionTypes, STATUS } from 'redux/constants/index'

export const shopsState = {
  list: {
    message: '',
    data: [],
    searchParams: {},
    status: STATUS.IDLE,
    reachedEnd: false
  },
  newShops: {
    message: '',
    data: [],
    searchParams: {},
    status: STATUS.IDLE,
    reachedEnd: false
  },
  details: {
    message: '',
    data: {},
    status: STATUS.IDLE
  },
  orders: {
    shopId: '',
    message: '',
    data: [],
    status: STATUS.IDLE
  },
  completedOrders: {
    shopId: '',
    message: '',
    data: [],
    status: STATUS.IDLE
  },
  products: {
    shopId: '',
    message: '',
    data: [],
    status: STATUS.IDLE
  },
  verify: {
    message: '',
    status: STATUS.IDLE
  },
  locations: {
    message: '',
    data: [],
    status: STATUS.IDLE
  },
  delete: {
    message: '',
    data: {},
    status: STATUS.IDLE
  },
  export: {
    message: '',
    data: {},
    status: STATUS.IDLE
  },
  settings: {
    message: '',
    data: {},
    status: STATUS.IDLE
  },
  updateSettings: {
    message: '',
    data: {},
    status: STATUS.IDLE
  },
  editShopLogo: {
    message: '',
    data: {},
    status: STATUS.IDLE
  },
  slots: {
    message: '',
    data: [],
    status: STATUS.IDLE
  },
  slotDetails: {
    message: '',
    data: {},
    status: STATUS.IDLE
  },
  updateSlot: {
    message: '',
    data: {},
    status: STATUS.IDLE
  },
  addSlot: {
    message: '',
    data: {},
    status: STATUS.IDLE
  },
  deleteSlot: {
    message: '',
    data: {},
    status: STATUS.IDLE
  },
  shopAnalytics: {
    message: '',
    data: {},
    status: STATUS.IDLE
  },
  shopGroups: {
    message: '',
    data: [],
    status: STATUS.IDLE
  },
}

export default {
  shops: handleActions(
    {
      [ActionTypes.GET_SHOPS]: (state, { payload }) =>
        immutable(state, {
          list: {
            status: { $set: STATUS.RUNNING },
            searchParams: { $set: payload },
            reachedEnd: { $set: false }
          }
        }),
      [ActionTypes.GET_SHOPS_SUCCESS]: (state, { payload }) => {
        let data = _.cloneDeep(state.list.data)
        if (state.list.searchParams && state.list.searchParams.skip === 0) {
          data = payload
        } else {
          data = [...data, ...payload]
        }
        return immutable(state, {
          list: {
            data: { $set: data },
            status: { $set: STATUS.READY },
            reachedEnd: { $set: !payload.length || payload.length !== state.list.searchParams.limit }
          }
        })
      },
      [ActionTypes.GET_SHOPS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          list: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR },
            searchParams: { $set: {} }
          }
        }),

      [ActionTypes.GET_NEW_SHOPS]: (state, { payload }) =>
        immutable(state, {
          newShops: {
            status: { $set: STATUS.RUNNING },
            searchParams: { $set: payload },
            reachedEnd: { $set: false }
          }
        }),
      [ActionTypes.GET_NEW_SHOPS_SUCCESS]: (state, { payload }) => {
        let data = _.cloneDeep(state.newShops.data)
        if (state.newShops.searchParams && state.newShops.searchParams.skip === 0) {
          data = payload
        } else {
          data = [...data, ...payload]
        }
        return immutable(state, {
          newShops: {
            data: { $set: data },
            status: { $set: STATUS.READY },
            reachedEnd: { $set: !payload.length || payload.length !== state.newShops.searchParams.limit }
          }
        })
      },
      [ActionTypes.GET_NEW_SHOPS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          newShops: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR },
            searchParams: { $set: {} }
          }
        }),

      [ActionTypes.GET_SHOP_LOCATION]: (state, { payload }) =>
        immutable(state, {
          locations: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.GET_SHOP_LOCATION_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          locations: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        })
      },
      [ActionTypes.GET_SHOP_LOCATION_FAILURE]: (state, { payload }) =>
        immutable(state, {
          locations: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.GET_SHOP_DETAILS]: (state, { payload }) =>
        immutable(state, {
          details: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.GET_SHOP_DETAILS_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          details: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        })
      },
      [ActionTypes.GET_SHOP_DETAILS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          details: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.GET_SHOP_PRODUCTS]: (state, { payload }) =>
        immutable(state, {
          products: {
            shopId: { $set: payload.id },
            status: { $set: STATUS.RUNNING },
            searchParams: { $set: payload },
            reachedEnd: { $set: false }
          }
        }),
      [ActionTypes.GET_SHOP_PRODUCTS_SUCCESS]: (state, { payload }) => {
        let data = _.cloneDeep(state.products.data)
        if (state.products.searchParams && state.products.searchParams.skip === 0) {
          data = payload
        } else {
          data = [...data, ...payload]
        }
        return immutable(state, {
          products: {
            data: { $set: data },
            status: { $set: STATUS.READY },
            searchParams: { $set: state.products.searchParams },
            reachedEnd: { $set: !payload.length || payload.length !== state.products.searchParams.limit }
          }
        })
      },
      [ActionTypes.GET_SHOP_PRODUCTS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          products: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.GET_SHOP_ACTIVE_ORDERS]: (state, { payload }) =>
        immutable(state, {
          orders: {
            shopId: { $set: payload.id },
            status: { $set: STATUS.RUNNING },
            searchParams: { $set: payload },
            reachedEnd: { $set: false }
          }
        }),
      [ActionTypes.GET_SHOP_ACTIVE_ORDERS_SUCCESS]: (state, { payload }) => {
        let data = _.cloneDeep(state.orders.data)
        if (state.orders.searchParams && state.orders.searchParams.skip === 0) {
          data = payload
        } else {
          data = [...data, ...payload]
        }
        return immutable(state, {
          orders: {
            data: { $set: data },
            status: { $set: STATUS.READY },
            reachedEnd: { $set: !payload.length || payload.length !== state.orders.searchParams.limit }
          }
        })
      },
      [ActionTypes.GET_SHOP_ACTIVE_ORDERS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          orders: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.GET_SHOP_COMPLETED_ORDERS]: (state, { payload }) =>
        immutable(state, {
          completedOrders: {
            shopId: { $set: payload.id },
            status: { $set: STATUS.RUNNING },
            searchParams: { $set: payload },
            reachedEnd: { $set: false }
          }
        }),
      [ActionTypes.GET_SHOP_COMPLETED_ORDERS_SUCCESS]: (state, { payload }) => {
        let data = _.cloneDeep(state.completedOrders.data)
        if (state.completedOrders.searchParams && state.completedOrders.searchParams.skip === 0) {
          data = payload
        } else {
          data = [...data, ...payload]
        }
        return immutable(state, {
          completedOrders: {
            data: { $set: data },
            status: { $set: STATUS.READY },
            reachedEnd: { $set: !payload.length || payload.length !== state.completedOrders.searchParams.limit }
          }
        })
      },
      [ActionTypes.GET_SHOP_COMPLETED_ORDERS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          completedOrders: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.VERIFY_SHOP]: (state, { payload }) =>
        immutable(state, {
          verify: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.VERIFY_SHOP_SUCCESS]: (state, { payload }) => {
        let details = _.cloneDeep(state.details)
        if (details && details.data && details.data.id === payload.id) {
          details.data.verification_status = payload.status
        }
        const { list, newShops } = _.cloneDeep(state)
        const listIndex = list.data.findIndex(item => item.id === payload.id)
        const newShopIndex = newShops.data.findIndex(item => item.id === payload.id)
        if (payload.status) {
          if (newShopIndex > -1) {
            newShops.data.splice(newShopIndex, 1)
          }
        } else {
          if (listIndex > -1) {
            list.data.splice(listIndex, 1)
          }
        }
        return immutable(state, {
          list: { $set: list },
          newShops: { $set: newShops },
          details: { $set: details },
          verify: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        })
      },
      [ActionTypes.VERIFY_SHOP_FAILURE]: (state, { payload }) =>
        immutable(state, {
          verify: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.DELETE_SHOP]: (state, { payload }) =>
        immutable(state, {
          delete: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.DELETE_SHOP_SUCCESS]: (state, { payload }) => {
        let list = _.cloneDeep(state.list)
        if (list && list.data) {
          const index = list.data.findIndex(item => item.id === payload)
          if (index > -1) {
            list.data.splice(index, 1)
          }
        }
        let newShops = _.cloneDeep(state.newShops)
        if (newShops && newShops.data) {
          const index = newShops.data.findIndex(item => item.id === payload)
          if (index > -1) {
            newShops.data.splice(index, 1)
          }
        }
        return immutable(state, {
          list: { $set: list },
          newShops: { $set: newShops },
          delete: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        })
      },
      [ActionTypes.DELETE_SHOP_FAILURE]: (state, { payload }) =>
        immutable(state, {
          delete: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.EXPORT_PRODUCT]: (state, { payload }) =>
        immutable(state, {
          export: {
            status: { $set: STATUS.RUNNING },
            searchParams: { $set: payload }
          }
        }),
      [ActionTypes.EXPORT_PRODUCT_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          export: {
            data: { $set: payload },
            status: { $set: STATUS.READY },
            searchParams: { $set: {} }
          }
        })
      },
      [ActionTypes.EXPORT_PRODUCT_FAILURE]: (state, { payload }) =>
        immutable(state, {
          export: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR },
            searchParams: { $set: {} }
          }
        }),

      [ActionTypes.GET_SHOP_SETTINGS]: (state, { payload }) =>
        immutable(state, {
          settings: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.GET_SHOP_SETTINGS_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          settings: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        })
      },
      [ActionTypes.GET_SHOP_SETTINGS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          settings: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.UPDATE_SHOP_SETTINGS]: (state, { payload }) =>
        immutable(state, {
          updateSettings: {
            status: { $set: STATUS.RUNNING },
            type: { $set: payload.type }
          }
        }),
      [ActionTypes.UPDATE_SHOP_SETTINGS_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          updateSettings: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        })
      },
      [ActionTypes.UPDATE_SHOP_SETTINGS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          updateSettings: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.EDIT_SHOP_LOGO]: (state, { payload }) =>
        immutable(state, {
          editShopLogo: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.EDIT_SHOP_LOGO_SUCCESS]: (state, { payload }) => {
        const details = _.cloneDeep(state.details)
        if (details.data && details.data.id) {
          details.data.image_url = payload.url
        }
        return immutable(state, {
          details: { $set: details },
          editShopLogo: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        })
      },
      [ActionTypes.EDIT_SHOP_LOGO_FAILURE]: (state, { payload }) =>
        immutable(state, {
          editShopLogo: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.GET_SHOP_SLOTS]: (state, { payload }) =>
        immutable(state, {
          slots: {
            status: { $set: STATUS.RUNNING },
            searchParams: { $set: payload }
          }
        }),
      [ActionTypes.GET_SHOP_SLOTS_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          slots: {
            data: { $set: payload },
            status: { $set: STATUS.READY },
            searchParams: { $set: {} }
          }
        })
      },
      [ActionTypes.GET_SHOP_SLOTS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          slots: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR },
            searchParams: { $set: {} }
          }
        }),

      [ActionTypes.GET_SHOP_SLOT_DETAILS]: (state, { payload }) =>
        immutable(state, {
          slotDetails: {
            status: { $set: STATUS.RUNNING },
            searchParams: { $set: payload }
          }
        }),
      [ActionTypes.GET_SHOP_SLOT_DETAILS_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          slotDetails: {
            data: { $set: payload },
            status: { $set: STATUS.READY },
            searchParams: { $set: {} }
          }
        })
      },
      [ActionTypes.GET_SHOP_SLOT_DETAILS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          slotDetails: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR },
            searchParams: { $set: {} }
          }
        }),

      [ActionTypes.UPDATE_SHOP_SLOT]: (state, { payload }) =>
        immutable(state, {
          updateSlot: {
            status: { $set: STATUS.RUNNING },
            searchParams: { $set: payload }
          }
        }),
      [ActionTypes.UPDATE_SHOP_SLOT_SUCCESS]: (state, { payload }) => {
        let slots = _.cloneDeep(state.slots.data)
        const index = slots.findIndex(item => item.id === payload.id)
        if (index > -1) {
          slots.splice(index, 1, payload)
        } else {
          slots.push(payload)
        }
        return immutable(state, {
          slots: {
            data: { $set: slots }
          },
          updateSlot: {
            data: { $set: payload },
            status: { $set: STATUS.READY },
            searchParams: { $set: {} }
          }
        })
      },
      [ActionTypes.UPDATE_SHOP_SLOT_FAILURE]: (state, { payload }) =>
        immutable(state, {
          updateSlot: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR },
            searchParams: { $set: {} }
          }
        }),

      [ActionTypes.ADD_SHOP_SLOT]: (state, { payload }) =>
        immutable(state, {
          addSlot: {
            status: { $set: STATUS.RUNNING },
            searchParams: { $set: payload }
          }
        }),
      [ActionTypes.ADD_SHOP_SLOT_SUCCESS]: (state, { payload }) => {
        let slots = _.cloneDeep(state.slots.data)
        slots.push(payload)
        return immutable(state, {
          slots: {
            data: { $set: slots }
          },
          addSlot: {
            data: { $set: payload },
            status: { $set: STATUS.READY },
            searchParams: { $set: {} }
          }
        })
      },
      [ActionTypes.ADD_SHOP_SLOT_FAILURE]: (state, { payload }) =>
        immutable(state, {
          addSlot: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR },
            searchParams: { $set: {} }
          }
        }),

      [ActionTypes.DELETE_SHOP_SLOT]: (state, { payload }) =>
        immutable(state, {
          deleteSlot: {
            status: { $set: STATUS.RUNNING },
            searchParams: { $set: payload }
          }
        }),
      [ActionTypes.DELETE_SHOP_SLOT_SUCCESS]: (state, { payload }) => {
        let slots = _.cloneDeep(state.slots.data)
        const index = slots.findIndex(item => item.id === payload.id)
        if (index > -1) {
          slots.splice(index, 1)
        }
        return immutable(state, {
          slots: {
            data: { $set: slots }
          },
          deleteSlot: {
            data: { $set: payload },
            status: { $set: STATUS.READY },
            searchParams: { $set: {} }
          }
        })
      },
      [ActionTypes.DELETE_SHOP_SLOT_FAILURE]: (state, { payload }) =>
        immutable(state, {
          deleteSlot: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR },
            searchParams: { $set: {} }
          }
        }),
      [ActionTypes.GET_SHOP_ANALYTICS]: (state, { payload }) =>
        immutable(state, {
          shopAnalytics: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.GET_SHOP_ANALYTICS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          shopAnalytics: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        }),
      [ActionTypes.GET_SHOP_ANALYTICS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          shopAnalytics: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.GET_SHOP_GROUPS]: (state, { payload }) =>
        immutable(state, {
          shopGroups: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.GET_SHOP_GROUPS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          shopGroups: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        }),
      [ActionTypes.GET_SHOP_GROUPS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          shopGroups: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        })
    },
    shopsState
  )
}
